import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    siteKey: String,
    environment: String
  }

  connect() {
    if (this.environmentValue === "test") return;

    this.button.disabled = true;

    window.onloadTurnstileCallback = () => {
      this.renderTurnstile();
    };
  }

  renderTurnstile() {
    this.id = turnstile.render(this.element, {
      sitekey: this.siteKeyValue,
      callback: () => this.button.disabled = false
    });
  }

  disconnect() {
    if (this.id) turnstile.remove(this.id);
  }

  get form() {
    return this.element.closest("form");
  }

  get button() {
    return this.form.querySelector("input[type=submit]");
  }
}
